*,
*:after,
*:before {
	box-sizing: border-box;
}

body{
	color: #212529;
	font-family: Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	margin: 0;
	padding: 0;
	text-align: left;
}

.login-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #e9ecef;
	height: 100vh;
	margin: 0;
	padding: 0;
}

#bg_video {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}

.login-box {
	width: 360px;

}

.login-box:before{
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	content: '';
	opacity: .65;
	background: rgb(0,0,0);
	background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.35896365382090334) 50%, rgba(0,0,0,1) 100%);
	z-index: 0;
}

.text-center {
	text-align: center!important;
}

.card{
	word-wrap: break-word;
	background-clip: border-box;
	background-color: #fff;
	border: 0 solid rgba(0,0,0,.125);
	border-radius: 0.25rem;
	display: flex;
	flex-direction: column;
	min-width: 0;
	position: relative;
}

.card-primary.card-outline {
	border-top: 6px solid #e80505;
	border-bottom: 6px solid #007bff;
}

.card-header {
	background-color: transparent;
	border-bottom: 1px solid rgba(0,0,0,.125);
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem;
	padding: .75rem 1.25rem;
	position: relative;
}

.card-header:first-child {
	border-radius: .25rem .25rem 0 0;
}

.card-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1.25rem;
}

.card-body:after, .card-footer:after, .card-header:after {
	clear: both;
	content: "";
	display: block;
}


/* GRID */
.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -7.5px;
	margin-right: -7.5px;
}
.col-8 {
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}
.col-4 {
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
	padding-left: 7.5px;
	padding-right: 7.5px;
	position: relative;
	width: 100%;
}

/* FORMs */
.input-group {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	width: 100%;
}

.input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
	flex: 1 1 auto;
	margin-bottom: 0;
	min-width: 0;
	position: relative;
}

.form-control {
	background-clip: padding-box;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	box-shadow: inset 0 0 0 transparent;
	color: #495057;
	display: block;
	font-size: 1rem;
	font-weight: 400;
	height: calc(2.25rem + 2px);
	line-height: 1.5;
	padding: .375rem .75rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	width: 100%;
}

.form-control:focus {
	background-color: #fff;
	border-color: #80bdff;
	box-shadow: inset 0 0 0 transparent;
	color: #495057;
	outline: 0;
}
.input-group>.form-control:focus {
	z-index: 3;
}

input[type=checkbox], input[type=radio] {
	box-sizing: border-box;
	padding: 0;
}

.btn {
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: .25rem;
	color: #212529;
	display: inline-block;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	padding: .375rem .75rem;
	text-align: center;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	user-select: none;
	vertical-align: middle;
}

.btn-primary {
	background-color: #007bff;
	border-color: #007bff;
	box-shadow: none;
	color: #fff;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
	background-color: #0069d9;
	border-color: #0062cc;
	color: #fff;
}

[class*=icheck-] {
	margin-bottom: 6px!important;
	margin-top: 6px!important;
	min-height: 22px;
	padding-left: 0;
}
[class*=icheck-]>input:first-child {
	margin: 0;
	opacity: 0;
	position: absolute!important;
}
[class*=icheck-]>label {
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 0;
	min-height: 22px;
	padding-left: 29px!important;
	position: relative;
	vertical-align: top;
}
.icheck-primary>input:first-child:checked+input[type=hidden]+label:before, .icheck-primary>input:first-child:checked+label:before {
	background-color: #007bff;
	border-color: #007bff;
}
[class*=icheck-]>input:first-child+input[type=hidden]+label:before, [class*=icheck-]>input:first-child+label:before {
	border: 1px solid #d3cfc8;
	border-radius: 0;
	content: "";
	display: inline-block;
	height: 22px;
	margin-left: -29px;
	position: absolute;
	width: 22px;
}
[class*=icheck-]>input:first-child:checked+input[type=hidden]+label:after, [class*=icheck-]>input:first-child:checked+label:after {
	border: 2px solid #fff;
	border-left: none;
	border-top: none;
	content: "";
	display: inline-block;
	height: 10px;
	left: 0;
	position: absolute;
	top: 0;
	transform: translate(7.75px,4.5px) rotate(45deg);
	-ms-transform: translate(7.75px,4.5px) rotate(45deg);
	width: 7px;
}
label:not(.form-check-label):not(.custom-file-label) {
	font-weight: 700;
}


/* UTILITY */
.mb-3, .my-3 {
	margin-bottom: 1rem!important;
}

.alert {
	position: relative;
	padding: .75rem;
	border: 0px solid transparent;
	font-size: 0.8rem;
}

.alert-danger {
	color: #fff;
	background-color: #e80505;
	border-color: #e80505;
}

.text-bold{
	font-weight: bold;
}


/* TYPO */
h1, h2, h3, h4, h5, h6 {
	margin-bottom: .5rem;
	margin-top: 0;
	font-size: 2.5rem;

}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: inherit;
	font-family: inherit;
	font-weight: 600;
	line-height: 1.2;
	margin-bottom: .5rem;
}

img, svg {
	vertical-align: middle;
}

.img-fluid, .img-thumbnail {
	height: auto;
	max-width: 100%;
}

.img-thumbnail {
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: .25rem;
	box-shadow: 0 1px 2px rgba(0,0,0,.075);
	padding: .25rem;
}
.img-size-64 {
	width: 64px;
	height: auto;
}

.invalid-feedback{
	color: #dc3545;
	font-weight: bold;
	font-size: 80%;
	display: inline-block;
}

/* MEDIA */

//@media screen and (max-device-width: 800px) {
//	.login-page {
//		background: #011738;
//		//background: url("../img/plane2.jpg") #000 no-repeat center center fixed;
//	}
//	#bg_video {
//		display: none;
//	}
//}

